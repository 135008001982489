/** @jsx jsx */
import Alert from '@fify.at/gatsby-theme-base/src/components/Alert/Alert';
import {
  ControlledForm,
  handleNetlifySubmit,
  SubmitButton,
  TextArea,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import HomePageLayout from '../../components/HomePageLayout';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import React from 'react';
import { jsx } from 'theme-ui';
import * as Yup from 'yup';
import { graphql } from 'gatsby';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';

export default function Kontakt({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Kontakt"
      description="Sie haben Fragen oder ein wichtiges Anliegen? Dann freuen wir uns auf Ihre Kontaktaufnahme."
      image={data.hero.childImageSharp.fluid}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="WirKaufenDeinHaus"
        subtitle="Kontakt"
        image={data.hero}
      />
      <IntroContent location={location} />
    </HomePageLayout>
  );
}

function ContactMap() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2657.237065200938!2d16.384397715652632!3d48.240563779231685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d065af70053ef%3A0xc777d871ded674cc!2sMillennium+Tower%2C+Handelskai+94-96%2C+1200+Wien!5e0!3m2!1sde!2sat!4v1566374579059!5m2!1sde!2sat"
      width="100%"
      height="450"
      frameBorder="0"
      title="kontakt Karte"
      style={{ border: 0 }}
      allowFullScreen
    />
  );
}

function IntroContent(location) {
  return (
    <>
      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>Kontakt</Heading>
        </div>
        <p>Gerne nehmen wir uns für Sie Zeit!</p>
        <p>
          Sie haben Fragen oder ein wichtiges Anliegen? Dann freuen wir uns auf
          Ihre Kontaktaufnahme. Bitte nutzen Sie unser nachfolgendes
          Kontaktformular oder schreiben uns direkt an: 
          <a href="mailto:office@wirkaufendeinhaus.at">
            office@wirkaufendeinhaus.at
          </a>
        </p>
        <p>
          Unser Team kümmert sich so schnell wie möglich um Ihr Anliegen und
          hilft Ihnen gerne weiter.
        </p>
        <p>
          <strong>BUYMYHOME24 GmbH</strong>
          <br />
          <strong>Gewerblicher An- und Verkauf von Immobilien</strong>
          <br />
          Obere Landstrasse 9<br />
          3500 Krems
          <br />
          Österreich
        </p>

        <p>
          Tel: <a href="tel:+436601151400">+43 660 11 51 400</a>
          <br />
          E-Mail:{' '}
          <a href="mailto:office@wirkaufendeinhaus.at">
            office@wirkaufendeinhaus.at
          </a>
          <br />
          Website:{' '}
          <a href="http://www.wirkaufendeinhaus.at/">
            www.wirkaufendeinhaus.at
          </a>
          <br />
          Die nach dem E-Commerce-Gesetz (ECG) bereitgestellten Informationen
          können direkt im{' '}
          <a href="http://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=c7833a3e-e729-45c2-bdf4-15805170d159&StandortID=0&Suchbegriff=F%C3%BCrstl">
            wko.at Firmen A-Z
          </a>{' '}
          abgerufen werden.
          <br />
        </p>
      </Section>
      <Section
        sx={{
          maxWidth: [null, null, 500, 760, 1100],
          marginX: 'auto',
          marginY: 7,
          boxShadow: `0 0 30px rgba(0,0,0,0.1)`,
          backgroundColor: '#fff',
        }}
      >
        <Heading
          sx={{ color: 'primary', fontWeight: 'bold', textAlign: 'center' }}
        >
          Kontaktformular
        </Heading>
        <ContactForm location={location} />
        <p sx={{ '&&&': { marginTop: 4 } }}>
          <small>
            * <Link to="/datenschutzerklaerung/">Datenschutz</Link> ist uns
            besonders wichtig! Seien Sie versichert, dass wir Ihre Daten stets
            diskret und sicher verarbeiten.
          </small>
        </p>
      </Section>
    </>
  );
}

function ContactForm({ location }) {
  const [isSubmitted, setSubmitted] = React.useState(false);
  if (isSubmitted) {
    return <Alert variant="success">Danke für Ihre Nachricht!</Alert>;
  }
  return (
    <ControlledForm
      name="kontakt"
      initialValues={{
        vorname: '',
        nachname: '',
        strasse: '',
        plz: '',
        ort: '',
        telefon: '',
        email: '',
        anfrage: '',
      }}
      validationSchema={formSchema}
      onSubmit={(values, actions) =>
        handleNetlifySubmit(values, actions, location.href).then(() =>
          setSubmitted(true)
        )
      }
    >
      <TextField label="Vorname" name="vorname" required />
      <TextField label="Nachname" name="nachname" required />
      <TextField label="Straße" name="strasse" />
      <TextField label="PLZ" name="plz" />
      <TextField label="Ort" name="ort" />
      <TextField label="Telefon" name="telefon" required />
      <TextField label="E-Mail" name="email" required />
      <TextArea label="Ihre Anfrage" name="anfrage" rows="5" />

      <SubmitButton>Senden</SubmitButton>
    </ControlledForm>
  );
}

const formSchema = Yup.object().shape({
  vorname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  nachname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  telefon: Yup.string().required('Dieses Feld ist verpflichtend'),
  email: Yup.string()
    .trim()
    .email('Bitte eine gültige E-Mail-Adresse eingeben')
    .required('Dieses Feld ist verpflichtend'),
});

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-terminvereinbaren" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
